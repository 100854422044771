import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  buttonText: {
    marginLeft: "10px",
  },
  input: {
    display: "none",
  },
  image: {
    height: "100px",
  },
  changeImageBtn: {
    fontSize: "14px",
    color: "gray",
    fontWeight: 300,
    padding: "3px",
    display: "block",
    textAlign: "center",
  },
}));

function FilePicker(props) {
  const classes = useStyles();

  return (
    <div>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        onChange={props.onChange}
        multiple
        type="file"
      />
      <label htmlFor="contained-button-file">
        {props.children}
      </label>
    </div>
  );
}

function ImageDisplay(props) {
  const classes = useStyles();

  return (
    <div>
      <img src={props.src} alt="uploaded image" className={classes.image} />
      <FilePicker onChange={props.onChange}>
        <Button component="div" className={classes.changeImageBtn}>
          Change Image
        </Button>
      </FilePicker>
    </div>
  );
}

function UploadImageButton(props) {
  const classes = useStyles();

  return (
    <div>
      <FilePicker onChange={props.onChange}>
        <Button variant="contained" color="primary" component="span">
          <CloudUploadIcon />
          <span className={classes.buttonText}>Upload Image</span>
        </Button>
      </FilePicker>
    </div>
  );
}
export default function UploadButton(props) {
  return props.src
    ? <ImageDisplay {...props} />
    : <UploadImageButton {...props} />;
}
