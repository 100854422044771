import "../prototypes";

const defaultExpand = {
  cart: true,
  shipping: false,
  payment: false,
  shippingOptions: false,
};
const initialCheckoutState = {
  open: false,
  cart: [],
  expand: defaultExpand,
  items: [],
};

export default (state = initialCheckoutState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const itemIndex = state.items.partialMatch({
        _id: action.payload,
      });
      const currentCartIndex = state.cart.partialMatch({ _id: action.payload });

      const newCart = [...state.cart];
      if (currentCartIndex === -1) {
        newCart.push({
          ...state.items[itemIndex],
          quanity: 1,
        });
      } else {
        newCart[currentCartIndex].quanity++;
      }
      return {
        ...state,
        cart: newCart,
      };

    case "ADD_SIZED_ITEM_TO_CART":
      const { _id: sizedItemId, sizePrice, selectedSize } = action.payload;
      const sizedItemIndex = state.items.partialMatch({
        _id: sizedItemId,
      });
      const sizedItemCartIndex = state.cart.partialMatch({
        _id: sizedItemId,
        selectedSize,
      });

      const cartWithSizedItem = [...state.cart];

      if (sizedItemCartIndex === -1) {
        cartWithSizedItem.push({
          ...state.items[sizedItemIndex],
          quanity: 1,
          selectedSize,
          price: sizePrice,
          name: `${state.items[sizedItemIndex].name} (${selectedSize})`,
        });
      } else {
        cartWithSizedItem[sizedItemCartIndex].quanity++;
      }

      return {
        ...state,
        cart: cartWithSizedItem,
      };
    case "REMOVE_FROM_CART":
      const cart = [...state.cart];
      cart.splice(action.payload, 1);

      return {
        ...state,
        cart,
      };
    case "TOGGLE_CHECKOUT":
      return {
        ...state,
        expand: defaultExpand,
        open: !state.open,
      };
    case "UPDATE_INVENTORY":
      return { ...state, items: action.payload.items };
    case "UPDATE_ITEM_QUANITY":
      const updatedQuanityCart = [...state.cart];
      updatedQuanityCart[action.payload.index].quanity = action.payload.quanity;
      return { ...state, cart: updatedQuanityCart };
    case "UPDATE_CHECKOUT_SECTION":
      const closedSections = { ...defaultExpand, cart: false };
      const updatedSection =
        action.payload === "shippingOptions"
          ? { shippingOptions: !state.expand.shippingOptions, payment: true }
          : { [action.payload]: true };

      return {
        ...state,
        expand: { ...closedSections, ...updatedSection },
      };

    case "HANDLE_ADR_VAL_RESULTS":
      return {
        ...state,
        expand: { ...defaultExpand, cart: false, payment: true },
      };

    case "PAYMENT_SUCCESS":
      return {
        ...initialCheckoutState,
        items: state.items,
      };
    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
      };
    case "RESET_STATE":
      return initialCheckoutState;
    default:
      return state;
  }
};
