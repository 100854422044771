const initialSiteConfig = {
  title: "",
  tagline: "",
  supportEmail: "",
  stripePublicKey: "",
  modal: {
    show: false,
    type: "",
    title: "",
  },
  adminModal: {
    item: false,
    itemDelete: false,
    menu: false,
    menuDelete: false,
    menuUpdate: false,
    sizeRestriction: false,
  },
  showSnackbar: false,
  showOrderStatus: false,
};

export default (state = initialSiteConfig, action) => {
  switch (action.type) {
    case "UPDATE_SITE_INFO":
      return { ...state, ...action.payload };
    case "PAYMENT_SUCCESS":
      return {
        ...state,
        modal: { show: true, type: "paymentSuccess", title: "Order Placed!" },
      };
    case "SHOW_CONTACT_MODAL":
      return {
        ...state,
        modal: { show: true, type: "contactUs", title: "Need Help?" },
      };
    case "TOGGLE_SNACKBAR":
      if (action.payload.show && state.showSnackbar) return state;
      return {
        ...state,
        showSnackbar: action.payload.show,
        snackbarTxt: action.payload.msg,
      };
    case "PAYMENT_FAILURE":
      return {
        ...state,
        showSnackbar: true,
        snackbarTxt: "There was an issue accepting this card",
      };
    case "TOGGLE_ORDER_CHECK":
      return {
        ...state,
        showOrderStatus: !state.showOrderStatus,
      };
    case "HIDE_PAYMENT_SUCCESS":
      return {
        ...state,
        modal: {
          ...state.modal,
          show: false,
        },
      };
    case "TOGGLE_ADMIN_MODAL":
      return {
        ...state,
        adminModal: {
          ...state.adminModal,
          [action.payload]: !state.adminModal[action.payload],
        },
      };
    case "SHOW_SIZE_RESTRICTION":
      return {
        ...state,
        adminModal: {
          ...state.adminModal,
          item: false,
          sizeRestriction: true,
        },
      };

    case "HIDE_SIZE_RESTRICTION":
      return {
        ...state,
        adminModal: {
          ...state.adminModal,
          item: true,
          sizeRestriction: false,
        },
      };
    case "RESET_STATE":
      return {
        ...state,
        modal: {
          ...state.modal,
          show: false,
        },
      };
    default:
      return state;
  }
};
