import { createMuiTheme } from "@material-ui/core/styles";
import { primaryColor, secondaryColor } from "../theme.config.json";

export const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
});
