import React, { useEffect } from "react";
import { TopNav, BottomNav, Modal, OrderStatus } from "../Components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { resetState, clearSelectedMenu } from "../actions";
import { useDispatch } from "react-redux";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";

export default function OrderConf() {
  const conf = useSelector((state) => state.orders.conf);
  const history = useHistory();
  const dispatch = useDispatch();

  const styles = {
    buttonContainer: {
      marginTop: "64px",
      display: "flex",
      justifyContent: "center",
    },
  };

  useEffect(() => {
    dispatch(clearSelectedMenu());
  }, [dispatch]);

  const redirectHome = () => {
    dispatch(resetState());
    history.push("/");
  };

  if (!conf) redirectHome();
  return (
    <div>
      <TopNav />
      <Modal />
      <OrderStatus />
      <div className="title">
        <div className="title__text">Thank you for your order</div>
        <div className="title__text--sub">
          Your confirmation number is{" "}
          <span
            style={{
              color: "#6545a1",
              fontWeight: 600,
            }}
          >
            {conf}
          </span>
          . You will recieve an email confirmation shortly
        </div>
      </div>
      <div style={styles.buttonContainer}>
        <Button onClick={redirectHome} variant="contained" color="primary">
          <ShoppingBasketIcon className="continue-shopping" />
          Place Another Order
        </Button>
      </div>
      <BottomNav />
    </div>
  );
}
