import React from "react";
import { connect } from "react-redux";
import {
  handleInputChange,
  updateCheckoutSection,
  removeFromCart,
  updateQuanity,
} from "../actions";
import {
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  Card,
  CardContent,
  Typography,
  Box,
} from "@material-ui/core";
import CheckoutTextFields from "./CheckoutTextFields";
import ShippingCheckoutTextFields from "./ShippingCheckoutTextFields";
import { bindActionCreators } from "redux";
import "../prototypes";

class Cart extends React.Component {
  styles = {
    table: {
      margin: "0px 15px",
      width: "auto",
    },
    summaryCard: {
      margin: "15px",
      minWidth: "250px",
      boxShadow: "5px 6px 18px -9px #979098",
    },
    summaryTitle: {
      borderBottom: "1px solid #ECECEC",
      paddingBottom: "5px",
    },
    container: {
      maxWidth: "100%",
    },
  };

  handleCheckoutInput(e, name) {
    const { value } = e.target;
    this.props.handleInputChange({
      name,
      value,
      group: "checkout",
    });
  }

  render() {
    const { cart } = this.props;
    const total = cart.reduce((a, c) => a + parseInt(c.price * c.quanity), 0);

    return (
      <div style={this.styles.container}>
        <Box display="flex" className="checkout-container">
          <Table style={this.styles.table}>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell />
                <TableCell align="right">Quanity</TableCell>
                <TableCell align="right">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.map((item, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Remove Item From Cart">
                      <IconButton onClick={() => this.props.removeFromCart(index)}>
                        <div className="checkout__cart__delete">REMOVE</div>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={item.quanity}
                      onChange={(e) => {
                        this.props.updateQuanity({
                          index,
                          quanity: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell align="right">
                    ${(item.quanity * (item.price / 100)).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div>
            <Card variant="outlined" style={this.styles.summaryCard}>
              <CardContent>
                <Typography variant="h6" style={this.styles.summaryTitle}>
                  SUMMARY
                </Typography>
                {this.props.taxRate > 0 && (
                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography>Subtotal</Typography>
                    <Typography>${(total / 100).toFixed(2)}</Typography>
                  </Box>
                )}
                {this.props.taxRate > 0 && (
                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography>Tax</Typography>
                    <Typography>${((total / 100) * this.props.taxRate).toFixed(2)}</Typography>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between" mt={1}>
                  <Typography>Order Total</Typography>
                  <Typography>
                    $
                    {(
                      parseFloat((total / 100).toFixed(2)) +
                      parseFloat(((total / 100) * this.props.taxRate).toFixed(2))
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </div>
        </Box>
        {this.props.shipping ? <ShippingCheckoutTextFields /> : <CheckoutTextFields />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.checkout,
    ...state.textInput.checkout,
    pickupTimesOptions: state.menu.menus.find((menu) => menu._id === state.menu.selectedMenuId)
      .pickupTimes,
    taxRate: state.config.taxRate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleInputChange,
      updateCheckoutSection,
      removeFromCart,
      updateQuanity,
    },
    dispatch
  );
};

const connectedCart = connect(mapStateToProps, mapDispatchToProps)(Cart);

export default connectedCart;
