import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSiteInfo, updateMenu, updateCategory, clearCart } from "../actions";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";

class AppTitle extends Component {
  componentDidMount() {
    this.props.getSiteInfo();
  }

  styles = {
    shippingLink: {
      position: "absolute",
      top: "78px",
      right: "16px",
    },
    shippingButton: {
      fontWeight: 100,
    },
    shippingLabel: {
      textShadow: "3px 2px 3px rgba(58, 31, 54, 0.3)",
      fontFamily: `"Cinzel", serif`,
    },
  };
  render() {
    const showShipping = () => {
      this.props.history.push("/shipping");
      this.props.updateMenu("SHIPPABLE");
      this.props.updateCategory("ENTREE");
      this.props.clearCart();
    };
    const isShippingAvailable = !this.props.shipping && this.props.shippingItemsAvailable;
    return (
      <div className={isShippingAvailable ? "title--dropped" : "title"}>
        <div className="title__text">{this.props.title}</div>
        <div className="title__text--sub">{this.props.tagline}</div>
        {this.props.menu && (
          <div className="title__text--sub menu__title">
            {this.props.menu.name}
            {this.props.menu.description ? ` - ${this.props.menu.description}` : ""}
          </div>
        )}
        {isShippingAvailable && (
          <div style={this.styles.shippingLink} className="title__shipping">
            <div style={this.styles.shippingLabel}>Intrested in getting items shipped?</div>
            <Button style={this.styles.shippingButton} onClick={showShipping}>
              Check Out Our Shippable Products
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.config,
    shippingItemsAvailable: state.menu.menus.find((m) => m._id === "SHIPPABLE"),
    menu:
      state.menu.menus[
        state.menu.menus.findIndex((menu) => menu._id === state.menu.selectedMenuId)
      ],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSiteInfo, updateMenu, updateCategory, clearCart }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppTitle));
