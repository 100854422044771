const getEmptyInputState = (required, defaultVal) => {
  return {
    value: defaultVal ? defaultVal : "",
    error: false,
    required,
  };
};

const getSizeRestrictionDefault = () => {
  return [
    {
      sizeName: getEmptyInputState(true, "Small"),
      price: getEmptyInputState(true),
    },
    {
      sizeName: getEmptyInputState(true, "Medium"),
      price: getEmptyInputState(true),
    },
    {
      sizeName: getEmptyInputState(true, "Large"),
      price: getEmptyInputState(true),
    },
  ];
};

const initInputState = {
  login: {
    username: getEmptyInputState(true),
    password: getEmptyInputState(true),
  },
  editItemForm: {
    name: getEmptyInputState(true),
    price: getEmptyInputState(false),
    category: getEmptyInputState(true),
    description: getEmptyInputState(false),
    sizeCheckbox: getEmptyInputState(false, false),
    shippableCheckbox: getEmptyInputState(false, false),
    imageUpload: getEmptyInputState(true),
  },
  addMenuForm: {
    name: getEmptyInputState(true),
    startDate: getEmptyInputState(
      true,
      new Date(Date.now()).setHours(0, 0, 0, 0) // < Beginning of current day
    ),
    endDate: getEmptyInputState(
      true,
      new Date(Date.now()).setHours(23, 59, 59, 999) // < End of current day
    ),
    imageUpload: getEmptyInputState(true),
    description: getEmptyInputState(false),
    pickupTimes: [getEmptyInputState(true)],
  },
  updateMenuForm: {
    name: getEmptyInputState(true),
    startDate: getEmptyInputState(true),
    endDate: getEmptyInputState(true),
    imageUpload: getEmptyInputState(true),
    description: getEmptyInputState(false),
    daysActive: {
      monday: {
        value: false,
        startTime: getEmptyInputState(true),
        endTime: getEmptyInputState(true),
      },
      tuesday: {
        value: false,
        startTime: getEmptyInputState(true),
        endTime: getEmptyInputState(true),
      },
      wednesday: {
        value: false,
        startTime: getEmptyInputState(true),
        endTime: getEmptyInputState(true),
      },
      thursday: {
        value: false,
        startTime: getEmptyInputState(true),
        endTime: getEmptyInputState(true),
      },
      friday: {
        value: false,
        startTime: getEmptyInputState(true),
        endTime: getEmptyInputState(true),
      },
      saturday: {
        value: false,
        startTime: getEmptyInputState(true),
        endTime: getEmptyInputState(true),
      },
      sunday: {
        value: false,
        startTime: getEmptyInputState(true),
        endTime: getEmptyInputState(true),
      },
    },
    pickupTimes: [],
  },
  checkout: {
    fullName: getEmptyInputState(true),
    kitchenNotes: getEmptyInputState(false),
    pickupTime: getEmptyInputState(true),
    emailAddress: getEmptyInputState(true),
  },
  sizeRestrictionForm: [...getSizeRestrictionDefault()],
};

export default (state = initInputState, action) => {
  switch (action.type) {
    case "UPDATE_TEXT_INPUT":
      const { name, value, group } = action.payload;
      return {
        ...state,
        [group]: {
          ...state[group],
          [name]: {
            ...state[group][name],
            value,
            error: false,
          },
        },
      };
    case "SET_DAYS_ACTIVE":
      return {
        ...state,
        updateMenuForm: {
          ...state.updateMenuForm,
          daysActive: action.payload,
        },
      };

    case "TOGGLE_INPUT_ERROR":
      const { name: errorName, group: errorGroup } = action.payload;
      return {
        ...state,
        [errorGroup]: {
          ...state[errorGroup],
          [errorName]: {
            ...state[errorGroup][errorName],
            error: !state[errorGroup][errorName].error,
          },
        },
      };
    case "TOGGLE_DAY_CHECKBOX":
      const {
        name: checkboxName,
        group: checkboxGroup,
        subGroup: checkboxSubGroup,
      } = action.payload;
      return {
        ...state,
        [checkboxGroup]: {
          ...state[checkboxGroup],
          [checkboxSubGroup]: {
            ...state[checkboxGroup][checkboxSubGroup],
            [checkboxName]: {
              ...state[checkboxGroup][checkboxSubGroup][checkboxName],
              value: !state[checkboxGroup][checkboxSubGroup][checkboxName].value,
            },
          },
        },
      };
    case "CHECK_DAY_CHECKBOX":
      const {
        name: confirmCheckboxName,
        group: confirmCheckboxGroup,
        subGroup: confirmCheckboxSubGroup,
      } = action.payload;
      return {
        ...state,
        [confirmCheckboxGroup]: {
          ...state[confirmCheckboxGroup],
          [confirmCheckboxSubGroup]: {
            ...state[confirmCheckboxGroup][confirmCheckboxSubGroup],
            [confirmCheckboxName]: {
              ...state[confirmCheckboxGroup][confirmCheckboxSubGroup][confirmCheckboxName],
              value: true,
            },
          },
        },
      };
    case "UNCHECK_DAY_CHECKBOX":
      const {
        name: uncheckCheckboxName,
        group: uncheckCheckboxGroup,
        subGroup: uncheckCheckboxSubGroup,
      } = action.payload;
      return {
        ...state,
        [uncheckCheckboxGroup]: {
          ...state[uncheckCheckboxGroup],
          [uncheckCheckboxSubGroup]: {
            ...state[uncheckCheckboxGroup][uncheckCheckboxSubGroup],
            [uncheckCheckboxName]: {
              ...state[uncheckCheckboxGroup][uncheckCheckboxSubGroup][uncheckCheckboxName],
              value: false,
            },
          },
        },
      };
    case "UPDATE_DAY_TIME":
      const { e: timeValue, group: dayGroup, day, timeType } = action.payload;
      const subGroup = "daysActive";
      return {
        ...state,
        [dayGroup]: {
          ...state[dayGroup],
          [subGroup]: {
            ...state[dayGroup][subGroup],
            [day]: {
              ...state[dayGroup][subGroup][day],
              [timeType]: {
                ...state[dayGroup][subGroup][day][timeType],
                value: new Date(timeValue),
              },
            },
          },
        },
      };
    case "UPDATE_PICKUP_TIME":
      const { value: pickupTime, group: pickupTimeGroup, index: puIndex } = action.payload;
      state[pickupTimeGroup].pickupTimes[puIndex] = {
        ...state[pickupTimeGroup].pickupTimes[puIndex],
        value: pickupTime,
      };
      return {
        ...state,
      };
    case "ADD_PICKUP_TIME":
      const { group: puGroup } = action.payload;
      return {
        ...state,
        [puGroup]: {
          ...state[puGroup],
          pickupTimes: [...state[puGroup].pickupTimes, getEmptyInputState()],
        },
      };
    case "DELETE_PICKUP_TIME":
      const { group: deleteGroup, index: deleteIndex } = action.payload;
      state[deleteGroup].pickupTimes.splice(deleteIndex, 1);
      return {
        ...state,
      };
    case "CLEAR_PICKUP_TIMES":
      state["updateMenuForm"].pickupTimes = [];
      return {
        ...state,
      };
    case "SET_SIZE_SETTING":
      if (!action.payload || !action.payload.length) {
        return {
          ...state,
          sizeRestrictionForm: [...getSizeRestrictionDefault()],
        };
      }
      return {
        ...state,
        sizeRestrictionForm: action.payload.map((size) => ({
          sizeName: getEmptyInputState(true, size.sizeName),
          price: getEmptyInputState(true, size.price / 100),
        })),
      };
    case "UPDATE_SIZE_SETTING":
      const { index, name: sizeName, value: sizeValue } = action.payload;

      const updatedSizeRestriction = [...state.sizeRestrictionForm];
      updatedSizeRestriction[index][sizeName].value = sizeValue;
      updatedSizeRestriction[index][sizeName].error = false;

      return {
        ...state,
        sizeRestrictionForm: updatedSizeRestriction,
      };

    case "TOGGLE_SIZE_ERROR":
      const { index: errorIndex, name: sizeErrorName } = action.payload;
      const sizeRestrictionFormWithError = [...state.sizeRestrictionForm];
      sizeRestrictionFormWithError[errorIndex][sizeErrorName].error = true;
      return {
        ...state,
        sizeRestrictionForm: sizeRestrictionFormWithError,
      };
    case "ADD_NEW_SIZE":
      return {
        ...state,
        sizeRestrictionForm: [
          ...state.sizeRestrictionForm,
          {
            sizeName: getEmptyInputState(true),
            price: getEmptyInputState(true),
          },
        ],
      };

    case "REMOVE_SIZE":
      const newSizeRestriction = [...state.sizeRestrictionForm];
      newSizeRestriction.splice(action.payload, 1);
      return {
        ...state,
        sizeRestrictionForm: newSizeRestriction,
      };
    case "RESET_STATE":
      return initInputState;
    default:
      return state;
  }
};
