import React from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { handleInputChange } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import UploadButton from "../UploadButton";
import * as API from "../../api";
import {
  toggleAdminModal,
  addNewItem,
  getMenuItems,
  clearSelectedItem,
  saveChangesToItem,
  showSizeRestriction,
  setAdminIsFetching,
} from "../../actions";

import {
  Select,
  FormControlLabel,
  Switch,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 200,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
    padding: theme.spacing(5),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  editSizes: {
    fontSize: "12px",
    color: "#717171",
    textDecoration: "underline",
  },
  buttonText: {
    marginLeft: "10px",
  },
  input: {
    display: "none",
  },
  image: {
    height: "100px",
  },
  changeImageText: {
    fontSize: "14px",
    textDecoration: "underline",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function AdminItemModal() {
  const classes = useStyles();
  const [showItemModal, formValues, menuName, menuId, itemId, sizes] = useSelector((state) => {
    return [
      state.config.adminModal.item,
      state.textInput.editItemForm,
      state.menu.details.name,
      state.admin.selectedMenu,
      state.admin.selectedItem,
      state.textInput.sizeRestrictionForm,
    ];
  });

  const dispatch = useDispatch();

  const toggleItemModal = function () {
    dispatch(toggleAdminModal("item"));
    dispatch(clearSelectedItem());
  };

  const isSizeRestricted = formValues.sizeCheckbox.value;

  const saveRequirementsMet =
    Object.values(formValues).every((fv) => !fv.required || fv.value) &&
    (formValues.price.value || isSizeRestricted);

  const handleSave = async () => {
    const details = {
      menuId,
      itemId,
      itemDetails: {
        name: formValues.name.value,
        desc: formValues.description.value,
        category: formValues.category.value.toUpperCase(),
        price: Math.round(parseFloat(formValues.price.value) * 100),
        image: formValues.imageUpload.value,
        sizeRestricted: isSizeRestricted,
        shippable: formValues.shippableCheckbox.value,
        sizes: isSizeRestricted
          ? sizes.map((s) => ({
              sizeName: s.sizeName.value,
              price: Math.round(parseFloat(s.price.value) * 100),
            }))
          : [],
      },
    };
    // Update item if item is selected, if no selected item exists, treat as new entry to db
    await dispatch(itemId ? saveChangesToItem(details) : addNewItem(details));
    await dispatch(getMenuItems(menuId));
    toggleItemModal();
  };

  const toggleSizeRestriction = function () {
    const value = !isSizeRestricted;
    dispatch(handleInputChange({ value, group: "editItemForm", name: "sizeCheckbox" }));

    if (value) {
      dispatch(showSizeRestriction());
    }
  };

  const handleImageUpload = async function (e) {
    dispatch(setAdminIsFetching(true));
    const { data } = await API.uploadImage(e.target.files[0]);
    dispatch(setAdminIsFetching(false));
    dispatch(
      handleInputChange({
        value: data.url,
        name: "imageUpload",
        group: "editItemForm",
      })
    );
  };
  const handleChange = function (e, name) {
    dispatch(handleInputChange({ value: e.target.value, name, group: "editItemForm" }));
  };

  return (
    <Dialog
      open={showItemModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{menuName} Items</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className={classes.container}>
            <TextField
              label="Item Name"
              className={classes.textField}
              onChange={(e) => handleChange(e, "name")}
              value={formValues.name.value}
            />
            <UploadButton src={formValues.imageUpload.value} onChange={handleImageUpload} />
          </div>
          <div className={classes.container}>
            <Input
              label="Price"
              className={classes.textField}
              onChange={(e) => handleChange(e, "price")}
              value={isSizeRestricted ? "N/A" : formValues.price.value}
              disabled={isSizeRestricted}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            <FormControl className={classes.textField}>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                id="demo-simple-select"
                value={formValues.category.value ? formValues.category.value.toUpperCase() : ""}
                onChange={(e) => handleChange(e, "category")}
              >
                <MenuItem value="ENTREE">Entree</MenuItem>
                <MenuItem value="SIDE">Side</MenuItem>
                <MenuItem value="DRINK">Drink</MenuItem>
                <MenuItem value="DESSERT">Dessert</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Description (Optional)"
            fullWidth
            onChange={(e) => handleChange(e, "description")}
            value={formValues.description.value}
          />
        </DialogContentText>
        <div className={classes.flex}>
          <Tooltip
            placement="right"
            title="Choices enable different selection options set at varying prices"
          >
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Enable Choices"
              onChange={toggleSizeRestriction}
              checked={isSizeRestricted}
            />
          </Tooltip>
          <FormControlLabel
            control={<Switch color="primary" />}
            label="Shippable"
            onChange={() =>
              handleChange(
                { target: { value: !formValues.shippableCheckbox.value } },
                "shippableCheckbox"
              )
            }
            checked={formValues.shippableCheckbox.value}
          />
        </div>

        {isSizeRestricted ? (
          <Button className={classes.editSizes} onClick={() => dispatch(showSizeRestriction())}>
            Edit Choice Options
          </Button>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={toggleItemModal} color="primary">
          Cancel
        </Button>
        <Button color="primary" autoFocus onClick={handleSave} disabled={!saveRequirementsMet}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminItemModal;
