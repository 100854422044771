import React, { useEffect, Fragment } from "react";
import { makeStyles, Typography, IconButton, Box, AppBar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { AdminNav } from "../Components";
import { AdminMenuModal, AdminDeleteMenuModal } from "../Components/Modals";
import { useDispatch, useSelector } from "react-redux";
import { getAdminMenuChoices, toggleAdminModal, selectMenuToDelete } from "../actions";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  title: {
    fontSize: "35px",
    textShadow: `3px 2px 3px rgba(58,31,54,.3)`,
    fontFamily: `"Cinzel",serif`,
  },
  adminView: {
    minHeight: "100vh",
    padding: "10px",
    textAlign: "center",
  },
  ordersBtn: {
    margin: "16px 16px 0",
  },
  menuGrid: {
    minHeight: "90vh",
  },
  menuCard: {
    width: "30%",
    minWidth: "300px",
    textAlign: "center",
    marginBottom: "16px",
  },
  menuImage: {
    height: "200px",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 10px",
  },
  newMenuButton: {
    position: "fixed",
    bottom: "50px",
    right: "50px",
  },
  statusIndicator: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "10px",
  },
  statusIndicatorActive: {
    backgroundColor: "#249c24",
  },
  statusIndicatorInactive: {
    backgroundColor: "#ce2a2a",
  },
  statusIndicatorText: {
    fontSize: "14px",
  },
  menuLink: {
    textDecoration: "none",
    color: "inherit",
  },
  emptyState: {
    color: "#9d9d9d",
    fontSize: "32px",
    fontWeight: 100,
  },
});

export default function AdminPortal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.menu.menus);

  const toggleMenuModal = function () {
    dispatch(toggleAdminModal("menu"));
  };

  const toggleMenuDeleteModal = async function (menuDetails) {
    await dispatch(selectMenuToDelete(menuDetails));
    dispatch(toggleAdminModal("menuDelete"));
  };

  useEffect(() => {
    dispatch(getAdminMenuChoices());
  }, []);

  return (
    <Fragment>
      <AdminMenuModal />
      <AdminDeleteMenuModal />
      <AdminNav />

      <div className={classes.adminView}>
        <Typography color="primary" variant="h3" textAlign="center" className={classes.title}>
          Add or Edit a Menu
        </Typography>
        <Box mt={6} display="flex" justifyContent="space-evenly" flexWrap="wrap">
          {menus.length > 0 ? (
            menus.map((menu) => {
              return (
                <Card className={classes.menuCard} key={menu._id}>
                  <CardMedia className={classes.menuImage} image={menu.image} title="Menu" />
                  <CardContent>
                    <Typography variant="h6">{menu.name}</Typography>
                  </CardContent>

                  <CardActions className={classes.cardActions}>
                    <div>
                      <div
                        className={`
                          ${classes.statusIndicator} 
                          ${
                            menu.isActive
                              ? classes.statusIndicatorActive
                              : classes.statusIndicatorInactive
                          }
                        `}
                      />
                      <span className={classes.statusIndicatorText}>
                        {menu.isActive ? "Active" : "Inactive"}
                      </span>
                    </div>
                    <div>
                      <Button
                        onClick={() =>
                          toggleMenuDeleteModal({
                            name: menu.name,
                            id: menu._id,
                          })
                        }
                        size="small"
                        color="primary"
                      >
                        Delete
                        <DeleteIcon style={{ margin: "0 0 4px 4px" }} />
                      </Button>
                      <Link to={`/admin/menu/${menu._id}`} className={classes.menuLink}>
                        <Button size="small" color="primary">
                          Edit Menu
                        </Button>
                      </Link>
                    </div>
                  </CardActions>
                </Card>
              );
            })
          ) : (
            <Grid item className={classes.emptyState}>
              You currently have no menus
            </Grid>
          )}
        </Box>
      </div>
      <div className={classes.newMenuButton}>
        <IconButton onClick={toggleMenuModal}>
          <AddIcon color="primary" fontSize="large" />
        </IconButton>
      </div>
    </Fragment>
  );
}
