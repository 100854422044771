export function handleInputChange(payload) {
  return { type: "UPDATE_TEXT_INPUT", payload };
}

export function setDaysActive(payload) {
  return { type: "SET_DAYS_ACTIVE", payload };
}

export function updateDaysActive(payload) {
  return { type: "UPDATE_DAYS_ACTIVE", payload };
}

export function toggleInputError(payload) {
  return { type: "TOGGLE_INPUT_ERROR", payload };
}

export function toggleCheckbox(payload) {
  return { type: "TOGGLE_CHECKBOX", payload };
}

export function setSizeSetting(payload) {
  return { type: "SET_SIZE_SETTING", payload };
}
export function updateSizeSettings(payload) {
  return { type: "UPDATE_SIZE_SETTING", payload };
}

export function addNewSize(payload) {
  return { type: "ADD_NEW_SIZE", payload };
}

export function removeSize(payload) {
  return { type: "REMOVE_SIZE", payload };
}

export function toggleSizeError(payload) {
  return { type: "TOGGLE_SIZE_ERROR", payload };
}

export function toggleDayCheckbox(payload) {
  return { type: "TOGGLE_DAY_CHECKBOX", payload };
}

export function checkDayCheckbox(payload) {
  return { type: "CHECK_DAY_CHECKBOX", payload };
}

export function uncheckDayCheckbox(payload) {
  return { type: "UNCHECK_DAY_CHECKBOX", payload };
}

export function updatePickupTime(payload) {
  return { type: "UPDATE_PICKUP_TIME", payload };
}

export function addPickupTime(payload) {
  return { type: "ADD_PICKUP_TIME", payload };
}

export function deletePickupTime(payload) {
  return { type: "DELETE_PICKUP_TIME", payload };
}

export function clearPickupTimes(payload) {
  return { type: "CLEAR_PICKUP_TIMES", payload };
}

export function handleDayTimeChange(payload) {
  return { type: "UPDATE_DAY_TIME", payload };
}
