import React, { Component } from "react";
import { connect } from "react-redux";
import { getMenuChoices, updateMenu } from "../actions";
import { bindActionCreators } from "redux";
import { Grid, Typography, Card, CardContent, CardMedia, Box } from "@material-ui/core";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";

class MenuSelection extends Component {
  componentDidMount() {
    this.props.getMenuChoices();
  }

  styles = {
    menuTitle: {
      fontFamily: `"Cormorant", serif`,
    },
    gridItem: {
      textAlign: "center",
    },
    card: {
      width: "30%",
      margin: "8px",
      cursor: "pointer",
      minWidth: "300px",
      boxShadow: "3px 3px 10px rgb(58 31 54 / 22%)",
    },
    cardImage: {
      height: "200px",
    },
    cardContent: {
      fontWeight: 100,
    },
  };
  render() {
    const { menuChoices } = this.props;

    return (
      <div>
        <div>
          <Grid container justify="center" alignItems="center">
            <Grid item style={this.styles.gridItem}>
              <Typography variant="h4" gutterBottom style={this.styles.menuTitle}>
                {menuChoices.length > 0 ? "Please Select A Menu" : "No Available Menus"}
              </Typography>
              <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
                {menuChoices.map((choice) => {
                  return (
                    <Card
                      style={this.styles.card}
                      onClick={() => this.props.updateMenu(choice._id)}
                    >
                      <CardMedia
                        style={this.styles.cardImage}
                        image={choice.image}
                        title={choice.name}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          style={this.styles.cardContent}
                        >
                          {choice.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          style={this.styles.cardContent}
                        >
                          {choice.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuChoices: state.menu.menus.filter((m) => m.show),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getMenuChoices, updateMenu }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuSelection);
