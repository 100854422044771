import React from "react";
import { connect } from "react-redux";
import {
  handleInputChange,
  updateCheckoutSection,
  removeFromCart,
  updateQuanity,
} from "../actions";
import { TextField, Box } from "@material-ui/core";
import { bindActionCreators } from "redux";
import "../prototypes";

class ShippingCheckoutTextFields extends React.Component {
  styles = {
    textField: {
      margin: "3px 8px",
      width: "200px",
    },
    fullWidthTextField: {
      width: "416px",
      margin: "3px 0",
    },
    table: {
      margin: "0px 15px",
      width: "auto",
    },
    summaryCard: {
      marginRight: "15px",
      minWidth: "250px",
      boxShadow: "5px 6px 18px -9px #979098",
    },
    summaryTitle: {
      borderBottom: "1px solid #ECECEC",
      paddingBottom: "5px",
    },
  };

  handleCheckoutInput(e, name) {
    const { value } = e.target;
    this.props.handleInputChange({
      name,
      value,
      group: "checkout",
    });
  }

  render() {
    return (
      <div>
        <Box display="flex" justifyContent="center" mt={3}>
          <TextField
            fullWidth
            style={this.styles.fullWidthTextField}
            label="Kitchen Notes"
            onChange={(e) => this.handleCheckoutInput(e, "kitchenNotes")}
            value={this.props.kitchenNotes.value}
            error={this.props.kitchenNotes.error}
          />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.checkout,
    ...state.textInput.checkout,
    pickupTimesOptions: state.menu.menus.find((menu) => menu._id === state.menu.selectedMenuId)
      .pickupTimes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleInputChange,
      updateCheckoutSection,
      removeFromCart,
      updateQuanity,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingCheckoutTextFields);
