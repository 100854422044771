import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';

export default function AdminOrderFilters({
  menus,
  menuFilter,
  pickupTimeFilter,
  onMenuChange,
  onPickupTimeChange,
}) {
  const menuOptions = [{ name: '(All Menus)', _id: 'ALL', pickupTimes: [] }].concat(menus);
  const pickupTimeOptions = ['(All Pickup Times)'].concat(menuFilter.pickupTimes);
  const styles = {
    filterTitle: {
      margin: '8px 16px',
    },
    tab: {
      height: 36,
    },
  };

  return (
    <div>
      <h2 style={styles.filterTitle}>Filters</h2>
      <Tabs
        style={styles.tab}
        value={menuOptions.findIndex((menu) => menu._id === menuFilter._id) || 0}
        defaultValue={0}
        aria-label='Outstanding Orders Menu Filter'
        variant='scrollable'
        scrollButtons='auto'
      >
        {menuOptions.map((menu, index) => (
          <Tab label={menu.name || '(Untitled Menu)'} onClick={() => onMenuChange(menu)} />
        ))}
      </Tabs>
      <div hidden={!menuFilter._id || menuFilter._id === 'ALL'}>
        <Tabs
          style={styles.tab}
          value={pickupTimeOptions.findIndex((pickupTime) => pickupTime === pickupTimeFilter) || 0}
          defaultValue={0}
          aria-label='Outstanding Orders Pickup Time Filter'
          variant='scrollable'
          scrollButtons='auto'
        >
          {pickupTimeOptions.map((pickupTime, index) => (
            <Tab
              label={pickupTime || '(Untitled Pickup Time)'}
              onClick={() => onPickupTimeChange(pickupTime)}
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
}
