import { combineReducers } from "redux";
import checkout from "./checkout";
import config from "./config";
import orders from "./orders";
import textInput from "./textInput";
import admin from "./admin";
import menu from "./menu";
import shipping from "./shipping";
import "../prototypes";

const rootReducers = combineReducers({
  checkout,
  shipping,
  orders,
  config,
  textInput,
  admin,
  menu,
});

export default rootReducers;
