import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAdminLogin } from "./actions";
import { AdminPortalLogin } from "./views";
import { Route } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

export default function PrivateRoute(props) {
  const dispatch = useDispatch();
  const { isAuthenticated, isFetching } = useSelector((state) => state.admin);

  useEffect(() => {
    const sessionToken = window.sessionStorage.getItem("token");
    if (sessionToken && window.location.href.includes("/admin")) {
      dispatch(checkAdminLogin());
    }
  }, []);

  if (isFetching) return <LinearProgress />;
  return isAuthenticated
    ? <Route {...props} />
    : <Route {...props} component={AdminPortalLogin} />;
}
