import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { addToCart, toggleSnackbar, updateSelectedSize, addSizedItemToCart } from "../actions";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";

import { bindActionCreators } from "redux";

const useStyles = makeStyles({
  card: {
    maxWidth: 245,
    margin: "2.5vh 10px",
    height: 300,
    overflow: "hidden",
    position: "relative",
    boxShadow: "3px 3px 10px rgb(58 31 54 / 22%)",
  },
  cardContent: {
    position: "relative",
    height: "100px",
    overflow: "scroll",
  },
  media: {
    height: 120,
    width: 245,
  },
  sizeSelect: {
    width: 85,
    position: "absolute",
    right: 10,
    top: 0,
  },
  shortTitle: {
    width: "calc(100% - 85px)",
    wordBreak: "break-word",
  },
  priceBox: {
    width: "100%",
    margin: "-8px",
    display: "flex",
    height: "50px",
    boxShadow: "-1px 0px 3px 0px rgb(226 225 225)",
    position: "absolute",
    bottom: "8px",
    lineHeight: "50px",
  },
});

function MediaCard(props) {
  const classes = useStyles();
  const {
    img,
    title,
    name,
    desc,
    price,
    _id,
    sizeRestricted,
    sizes,
    selectedSize,
    sizePrice,
  } = props;

  const handleClick = () => {
    if (sizeRestricted) {
      props.addSizedItemToCart({ _id, sizePrice, selectedSize });
    } else {
      props.addToCart(_id);
    }
    props.toggleSnackbar({ show: true, msg: "Item Added to Cart" });
  };

  const handleSizeChange = ({ target }) => {
    props.updateSelectedSize({ itemId: _id, choosenSize: target.value });
  };

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={img} title={title} />

      <CardContent className={`scrollbar ${classes.cardContent}`}>
        <Typography
          gutterBottom
          variant="h6"
          component="h2"
          className={sizeRestricted ? classes.shortTitle : null}
        >
          {name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {desc}
        </Typography>
        {sizeRestricted ? (
          <FormControl className={classes.sizeSelect}>
            <InputLabel id="size-label">Choose</InputLabel>
            <Select labelId="size-label" onChange={handleSizeChange} value={selectedSize}>
              {sizes.map((s) => (
                <MenuItem value={s.sizeName}>{s.sizeName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </CardContent>
      <CardActions>
        <div className={classes.priceBox}>
          {sizeRestricted ? (
            sizePrice ? (
              <div className="items__card__price">${(sizePrice / 100).toFixed(2)}</div>
            ) : sizes[0].price === sizes[sizes.length - 1].price ? (
              <div className="items__card__price">${(sizes[0].price / 100).toFixed(2)}</div>
            ) : (
              <div className="items__card__price price-range">
                {`$${sizes[0].price / 100} - $${sizes[sizes.length - 1].price / 100}`}
              </div>
            )
          ) : (
            <div className="items__card__price">${(price / 100).toFixed(2)}</div>
          )}
          <Grid container direction="row" justify="flex-end" alignItems="center">
            {sizeRestricted && !selectedSize ? (
              <Tooltip title="Choose option before adding to cart" position="top">
                <span>
                  <Button size="small" color="primary" disabled>
                    Add to Cart
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button onClick={handleClick} size="small" color="primary">
                Add to Cart
              </Button>
            )}
          </Grid>
        </div>
      </CardActions>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return { items: state.items, showSnackbar: state.config.showSnackbar };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addToCart, toggleSnackbar, updateSelectedSize, addSizedItemToCart },
    dispatch
  );
};
const ConnectedMediaCard = connect(mapStateToProps, mapDispatchToProps)(MediaCard);

export default ConnectedMediaCard;
