import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleCheckout, toggleOrderCheck, showContactModal } from "../../actions";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tooltip, Toolbar, IconButton, Fab } from "@material-ui/core";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    backgroundColor: "#fcbb87",
  },
  version: {
    marginLeft: "20px",
    fontWeight: 100,
    fontSize: "12px",
  },
}));

function Copyright(props) {
  return <div className="nav__bottom__copyright">{props.copyright} &#9400; 2020</div>;
}
function BottomAppBar(props) {
  const classes = useStyles();
  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar className="nav nav__bottom">
        <Tooltip title="Order Status">
          <IconButton color="inherit" onClick={props.toggleOrderCheck}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Contact Us">
          <IconButton edge="end" color="inherit" onClick={props.showContactModal}>
            <ContactSupportIcon />
          </IconButton>
        </Tooltip>

        {props.menuSelected ? (
          <Tooltip title="View Cart">
            <Fab aria-label="add" className={classes.fabButton} onClick={props.showCheckout}>
              <ShoppingCartIcon />
            </Fab>
          </Tooltip>
        ) : null}
        <div className={classes.grow} />
        {Copyright(props)}
        <div className={classes.version}>v{props.siteVersion}</div>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (state) => {
  return { ...state.config, menuSelected: !!state.menu.selectedMenuId };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { showCheckout: toggleCheckout, toggleOrderCheck, showContactModal },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(BottomAppBar);
