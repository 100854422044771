import React from "react";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleAdminModal,
  removeItemFromMenu,
  getMenuItems,
} from "../../actions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

function AdminDeleteItem() {
  const [showItemDeleteModal, itemId, menuId] = useSelector(
    (state) => {
      return [
        state.config.adminModal.itemDelete,
        state.admin.selectedItem,
        state.admin.selectedMenu,
      ];
    },
  );

  const dispatch = useDispatch();

  const toggleItemModal = function () {
    dispatch(toggleAdminModal("itemDelete"));
  };

  const handleSave = async () => {
    await dispatch(removeItemFromMenu({ itemId, menuId }));
    await dispatch(getMenuItems(menuId));
    toggleItemModal();
  };

  return (
    <Dialog
      open={showItemDeleteModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove this item from this menu?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleItemModal} color="primary">
          No, Cancel
        </Button>
        <Button color="primary" autoFocus onClick={handleSave}>
          Yes, Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminDeleteItem;
