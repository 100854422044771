import React, { Fragment } from "react";
import { ShippingCheckout, Items, MenuSelection, Checkout } from "../Containers";
import {
  Modal,
  OrderStatus,
  TopNav,
  Title,
  Categories,
  BottomAlert,
  BottomNav,
} from "../Components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Main(props) {
  const { isMenuSelected, menus } = useSelector((state) => ({
    isMenuSelected: !!state.menu.selectedMenuId,
    menus: state.menu.menus,
  }));
  const history = useHistory();
  if (props.shipping && menus.length === 0) history.push("/");

  const styles = {
    clearFix: {
      clear: "both",
      height: "100px",
    },
  };
  return (
    <Fragment>
      <Modal />
      <OrderStatus />
      {props.shipping ? <ShippingCheckout /> : <Checkout />}
      <TopNav />
      <Title shipping={props.shipping} />
      <Categories />
      {isMenuSelected ? <Items /> : <MenuSelection />}
      <BottomAlert />
      <div style={styles.clearFix}></div>
      <BottomNav />
    </Fragment>
  );
}
