import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

export default function Orders({ orders }) {
  const outstandingInvCounts = orders
    .map((order) => order.cart)
    .flat()
    .reduce((all, item) => {
      const itemName = (item.selectedSize
        ? `${item.name} - ${item.selectedSize}`
        : item.name
      ).trim();
      if (all.hasOwnProperty(itemName)) {
        all[itemName] = all[itemName] + item.qty;
      } else {
        all[itemName] = item.qty;
      }
      return all;
    }, {});

  const styles = {
    filterTitle: {
      fontSize: 16,
      fontWeight: 400,
      margin: '8px 16px',
      fontStyle: 'italic',
    },
    tab: {
      background: '#e7e7e7',
      height: 36,
    },
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label='order table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Item Name / Option</TableCell>
              <TableCell align='left'>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(outstandingInvCounts)
              .sort((a, b) => (a > b ? 1 : -1))
              .map((key) => (
                <TableRow>
                  <TableCell align='left'>{key}</TableCell>
                  <TableCell align='left'>{outstandingInvCounts[key]}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
