import {
  handleLogin,
  newItem,
  updateItem,
  deleteItem,
  newMenu,
  updateMenu,
  deleteMenu,
  validateToken,
  findAllMenus,
} from "../api";
import { toggleAdminModal } from "./config";

export function setAuthenticated(payload) {
  return { type: "SET_AUTHENTICATED", payload };
}

export function setAdminIsFetching(payload) {
  return { type: "SET_ADMIN_IS_FETCHING", payload };
}
export function updateSelectedMenu(payload) {
  return { type: "UPDATE_SELECTED_MENU", payload };
}

export function selectMenuToDelete(payload) {
  return { type: "SELECT_MENU_TO_DELETE", payload };
}

export function updateSelectedItem(payload) {
  return { type: "UPDATE_SELECTED_ITEM", payload };
}

export function clearSelectedItem(payload) {
  return { type: "CLEAR_SELECTED_ITEM", payload };
}

export function showSizeRestriction(payload) {
  return { type: "SHOW_SIZE_RESTRICTION", payload };
}

export function hideSizeRestriction(payload) {
  return { type: "HIDE_SIZE_RESTRICTION", payload };
}

export function getAdminMenuChoices(payload) {
  return function (dispatch) {
    return findAllMenus().then(({ data }) => {
      return dispatch({ type: "UPDATE_MENUS", payload: data });
    });
  };
}

export function validateUser(payload) {
  return function (dispatch) {
    return handleLogin(payload)
      .then(({ data }) => {
        window.sessionStorage.setItem("token", data.token);
        dispatch(setAuthenticated(true));
      })
      .catch((err) => {
        dispatch(setAuthenticated(false));
      });
  };
}

export function addNewItem(payload) {
  return function (dispatch) {
    return newItem(payload);
  };
}

export function saveChangesToItem(payload) {
  return function (dispatch) {
    return updateItem(payload);
  };
}

export function removeItemFromMenu(payload) {
  return function (dispatch) {
    return deleteItem(payload);
  };
}

export function createMenu(payload) {
  return function (dispatch) {
    return newMenu(payload).then((res) => {
      dispatch(getAdminMenuChoices());
      toggleAdminModal("menu");
    });
  };
}

export function updateCurrentMenu(payload) {
  return function (dispatch) {
    return updateMenu(payload);
  };
}

export function deleteCurrentMenu(payload) {
  return function (dispatch) {
    return deleteMenu(payload).then((res) => {
      dispatch(getAdminMenuChoices());
      toggleAdminModal("menuDelete");
    });
  };
}

export function checkAdminLogin(payload) {
  return function (dispatch) {
    dispatch(setAdminIsFetching(true));
    return validateToken()
      .then((data) => {
        dispatch(setAdminIsFetching(false));
        dispatch(setAuthenticated(true));
      })
      .catch((err) => {
        dispatch(setAdminIsFetching(false));
        dispatch(setAuthenticated(false));
      });
  };
}
