import { createMuiTheme } from "@material-ui/core/styles";

export const cartBadgeTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#d42f2f",
    },
    secondary: {
      main: "#ffc107",
    },
  },
});
