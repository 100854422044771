import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

class AdminNav extends Component {
  styles = {
    link: {
      textDecoration: 'none',
      color: '#ffffff',
    },
    navContents: {
      width: '100%',
    },
    btn: {
      margin: '0 12px',
    },
    wrapper: {
      position: 'absolute',
      right: 24,
    },
  };
  render() {
    return (
      <AppBar position='sticky'>
        <Toolbar className='nav nav__top'>
          <Link style={this.styles.link} to='/admin'>
            <Typography variant='h6'>Admin Panel</Typography>
          </Link>
          <div style={this.styles.wrapper}>
            <Link to='/admin/orders'>
              <Button color='secondary' variant='outlined' style={this.styles.btn}>
                Manage Orders
              </Button>
            </Link>

            <Link to='/'>
              <Button variant='outlined' color='secondary' style={this.styles.btn}>
                View Live Site
              </Button>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default AdminNav;
