import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  BottomNavigation as NavigationMenu,
  BottomNavigationAction as NavigationMenuAction,
} from "@material-ui/core";

import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import LocalDiningIcon from "@material-ui/icons/LocalDining";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIceCream, faAppleAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { updateCategory } from "../actions";

const useStyles = makeStyles({
  root: {
    width: 500,
    margin: "auto",
    maxWidth: "100%",
  },
});

export default function SimpleNavigationMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { menuSelected, selectedCategory } = useSelector((state) => {
    const { selectedCategory, selectedMenuId: menuSelected } = state.menu;
    return { menuSelected, selectedCategory };
  });

  const handleCategoryChange = (category) => dispatch(updateCategory(category));
  return (
    <div>
      {menuSelected ? (
        <NavigationMenu showLabels className={classes.root}>
          <NavigationMenuAction
            label="Entrees"
            onClick={() => handleCategoryChange("ENTREE")}
            icon={<LocalDiningIcon color={selectedCategory === "ENTREE" ? "primary" : ""} />}
          />
          <NavigationMenuAction
            onClick={() => handleCategoryChange("SIDE")}
            label="Sides"
            icon={
              <FontAwesomeIcon
                icon={faAppleAlt}
                size="2x"
                style={selectedCategory === "SIDE" ? { color: "#5E0168" } : {}}
              />
            }
          />
          <NavigationMenuAction
            onClick={() => handleCategoryChange("DESSERT")}
            label="Dessert"
            icon={
              <FontAwesomeIcon
                icon={faIceCream}
                size="2x"
                style={selectedCategory === "DESSERT" ? { color: "#5E0168" } : {}}
              />
            }
          />
          <NavigationMenuAction
            onClick={() => handleCategoryChange("DRINK")}
            label="Drinks"
            icon={<LocalCafeIcon color={selectedCategory === "DRINK" ? "primary" : ""} />}
          />
        </NavigationMenu>
      ) : null}
    </div>
  );
}
