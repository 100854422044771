import { getMenuInventory, getMenus, newItem } from "../api";

export function getMenuItems(payload) {
  return function (dispatch) {
    getMenuInventory(payload).then((inv) =>
      dispatch({ type: "UPDATE_INVENTORY", payload: inv.data || {} })
    );
  };
}

export function updateMenus(payload) {
  return { type: "UPDATE_MENUS", payload };
}

export function updateMenu(payload) {
  return { type: "UPDATE_SELECTED_MENU", payload };
}

export function updateCategory(payload) {
  return { type: "UPDATE_CATEGORY", payload };
}

export function getMenuChoices(payload) {
  return function (dispatch) {
    getMenus().then((menus) => dispatch(updateMenus(menus.data)));
  };
}

export function setAdminIsFetching(payload) {
  return { type: "SET_ADMIN_IS_FETCHING", payload };
}

export function clearSelectedMenu(payload) {
  return { type: "CLEAR_SELECTED_MENU", payload };
}

export function updateSelectedSize(payload) {
  return { type: "UPDATE_SELECTED_SIZE", payload };
}
