const initialMenuState = {
  selectedMenuId: null,
  details: {
    daysActive: [],
    image: null,
    isSpecial: null,
    name: null,
    items: [],
  },
  selectedCategory: "ENTREE",
  menus: [],
};

export default (state = initialMenuState, { type, payload }) => {
  switch (type) {
    case "UPDATE_INVENTORY":
      return {
        ...state,
        details: {
          ...state.details,
          ...payload,
          items: payload.items.map((item) => ({
            ...item,
            selectedSize: "",
            sizePrice: null,
          })),
        },
      };
    case "UPDATE_SELECTED_MENU":
      return {
        ...state,
        selectedMenuId: payload,
      };
    case "UPDATE_CATEGORY":
      return {
        ...state,
        selectedCategory: payload,
      };
    case "UPDATE_MENU_CHOICE":
      return {
        ...state,
        selectedMenuId: payload,
      };
    case "UPDATE_MENUS":
      return {
        ...state,
        menus: payload,
      };
    case "CLEAR_SELECTED_MENU":
      return {
        ...state,
        selectedMenuId: null,
      };

    case "UPDATE_SELECTED_SIZE":
      const { itemId, choosenSize } = payload;

      const sizeIndex = state.details.items.findIndex((item) => item._id === itemId);
      const updatedSizeItems = [...state.details.items];
      updatedSizeItems[sizeIndex].selectedSize = choosenSize;

      const updatedSizePrice = updatedSizeItems[sizeIndex].sizes.find(
        (s) => s.sizeName === choosenSize
      ).price;

      updatedSizeItems[sizeIndex].sizePrice = updatedSizePrice;

      return {
        ...state,
        details: {
          ...state.details,
          items: updatedSizeItems,
        },
      };
    case "RESET_STATE":
      return { ...initialMenuState, menus: state.menus };
    default:
      return state;
  }
};
