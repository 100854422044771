import React, { Component } from "react";
import { AppBar, Toolbar, Typography, Grid, Tooltip, Badge, IconButton } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { cartBadgeTheme } from "../../themes";
import { ThemeProvider } from "@material-ui/core";
import { connect } from "react-redux";
import { toggleCheckout, resetState } from "../../actions";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Radium from "radium";

class Navbar extends Component {
  styles = {
    link: {
      ":hover": {
        cursor: "pointer",
      },
    },
    shippingButton: {
      color: "white",
    },
    cornerText: {
      fontFamily: `'Cormorant', serif`,
    },
  };

  render() {
    const redirectHome = () => {
      this.props.resetState();
      this.props.history.push("/");
    };
    return (
      <AppBar position="sticky">
        <Toolbar className="nav nav__top">
          <div style={[this.styles.link]} onClick={redirectHome}>
            <Grid item>
              <Typography variant="h6" style={this.styles.cornerText}>
                {this.props.config.title}
              </Typography>
            </Grid>
          </div>
          <Grid item xs></Grid>

          {this.props.menu.selectedMenuId ? (
            <ThemeProvider theme={cartBadgeTheme}>
              <Grid item>
                <Tooltip title="View Cart">
                  <IconButton color="inherit" onClick={this.props.showCheckout}>
                    <Badge
                      badgeContent={this.props.checkout.cart.reduce((a, c) => a + c.quanity, 0)}
                      color="primary"
                    >
                      <ShoppingCartIcon className="nav--right" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Grid>
            </ThemeProvider>
          ) : null}
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showCheckout: toggleCheckout, resetState }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Radium(Navbar)));
