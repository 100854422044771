import React from "react";
import { Typography, Paper, Box, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  toggleAdminModal,
  handleInputChange,
  checkDayCheckbox,
  uncheckDayCheckbox,
  handleDayTimeChange,
  addPickupTime,
  updatePickupTime,
  clearPickupTimes,
  setDaysActive,
} from "../actions";

function AdminMenuBox() {
  const dispatch = useDispatch();
  const {
    daysActive,
    startDate,
    endDate,
    pickupTimes,
    name,
    imageUpload,
    description,
  } = useSelector((state) => {
    const {
      daysActive,
      startDate,
      endDate,
      pickupTimes,
      name,
      description,
      image: imageUpload,
    } = state.menu.details;
    return {
      daysActive,
      startDate,
      endDate,
      pickupTimes,
      imageUpload,
      description,
      name,
    };
  });

  const styles = {
    dateRangeContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "16px",
    },
    dateRangeDays: {
      fontSize: "14px",
      fontWeight: "100",
      marginRight: "4px",
    },
    dateRange: {
      marginTop: "16px",
      fontWeight: "100",
    },
  };

  const weekdays = [
    { long: "monday", short: "Mon" },
    { long: "tuesday", short: "Tue" },
    { long: "wednesday", short: "Wed" },
    { long: "thursday", short: "Thur" },
    { long: "friday", short: "Fri" },
    { long: "saturday", short: "Sat" },
    { long: "sunday", short: "Sun" },
  ];
  const dayRange =
    Object.keys(daysActive).length > 0
      ? weekdays.filter((day) => daysActive[day.long].active).map((day) => day.short)
      : [];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(
      date
    );
    const newDateString = `${day}/${month}/${year}`;
    return newDateString;
  };

  const dateRange = startDate && endDate ? `${formatDate(startDate)} - ${formatDate(endDate)}` : "";

  const toggleModal = function () {
    const group = "updateMenuForm";
    const inputFields = { startDate, endDate, name, imageUpload, description };
    for (let key in inputFields) {
      dispatch(
        handleInputChange({
          value: inputFields[key],
          name: key,
          group,
        })
      );
    }

    dispatch(setDaysActive(daysActive));
    dispatch(clearPickupTimes());
    pickupTimes.forEach((pickupTime, index) => {
      dispatch(addPickupTime({ group }));
      dispatch(updatePickupTime({ value: pickupTime, index, group }));
    });
    dispatch(toggleAdminModal("menuUpdate"));
  };

  return (
    <div>
      <Paper>
        <Box p={4}>
          <Typography variant="h6" component="h6">
            {name}
          </Typography>
          <Box mt={2}>
            <Typography variant="p">Availability:</Typography>
            <div style={styles.dateRangeContainer}>
              {dayRange.map((day, index) => {
                return (
                  <Typography style={styles.dateRangeDays} key={index}>
                    {day}
                  </Typography>
                );
              })}
            </div>
            <div style={styles.dateRange}>{dateRange}</div>
          </Box>
          <Box mt={2}>
            <Button onClick={toggleModal} variant="contained" color="secondary">
              Edit Info
            </Button>
          </Box>
        </Box>
      </Paper>
    </div>
  );
}

export default AdminMenuBox;
