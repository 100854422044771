import React from "react";
import { Drawer } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import { Cart, Payment, EmptyCart } from "../Components";
import { connect } from "react-redux";
import { toggleCheckout, updateCheckoutSection } from "../actions";
import { bindActionCreators } from "redux";

class Checkout extends React.Component {
  render() {
    const { open, cart } = this.props.checkout;
    return (
      <Drawer anchor="right" open={open}>
        <div>
          <IconButton color="inherit" onClick={this.props.hideCheckout}>
            <ArrowBack className="nav--right" />
          </IconButton>
          {cart.length ? (
            <>
              <Cart />
              <br />
              <Payment />
            </>
          ) : (
            <EmptyCart />
          )}
        </div>
      </Drawer>
    );
  }
}

const mapDisatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      hideCheckout: toggleCheckout,
      updateCheckoutSection,
    },
    dispatch
  );
};
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDisatchToProps)(Checkout);
