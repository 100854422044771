const initialAdminState = {
  isAuthenticated: false,
  isFetching: false,
  selectedMenu: null,
  selectedItem: null,
  menuToDelete: null,
};

export default (state = initialAdminState, action) => {
  switch (action.type) {
    case "SET_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case "SET_ADMIN_IS_FETCHING":
      return {
        ...state,
        isFetching: action.payload,
      };
    case "UPDATE_SELECTED_MENU":
      return {
        ...state,
        selectedMenu: action.payload,
      };
    case "SELECT_MENU_TO_DELETE":
      return {
        ...state,
        menuToDelete: action.payload,
      };
    case "UPDATE_SELECTED_ITEM":
      return {
        ...state,
        selectedItem: action.payload,
      };
    case "CLEAR_SELECTED_ITEM":
      return {
        ...state,
        selectedItem: null,
      };
    case "RESET_STATE":
      return initialAdminState;
    default:
      return state;
  }
};
