import React, { Component } from "react";
import ItemCard from "../Components/ItemCard";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { getMenuItems } from "../actions";
import { bindActionCreators } from "redux";

class Items extends Component {
  componentDidMount() {
    this.props.getMenuItems(this.props.selectedMenuId);
  }
  render() {
    const visibleItems = this.props.inventory.filter(
      (item) => item.category === this.props.selectedCategory
    );

    return (
      <div>
        <div className="items">
          <Grid container direction="row" justify="center" alignItems="center">
            {visibleItems.length > 0 ? (
              visibleItems.map((p) => {
                return (
                  <ItemCard
                    img={p.image}
                    name={p.name}
                    desc={p.desc}
                    price={p.price}
                    key={p._id}
                    _id={p._id}
                    sizeRestricted={p.sizeRestricted}
                    sizes={p.sizes}
                    selectedSize={p.selectedSize}
                    sizePrice={p.sizePrice}
                  />
                );
              })
            ) : (
              <div className="items--empty">No Items to Display</div>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inventory: state.menu.details.items,
    selectedCategory: state.menu.selectedCategory,
    selectedMenuId: state.menu.selectedMenuId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getMenuItems }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Items);
