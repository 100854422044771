import React from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSizeSettings,
  addNewSize,
  removeSize,
  hideSizeRestriction,
  toggleSizeError,
  toggleAdminModal,
} from "../../actions";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 200,
  },
  priceInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 100,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
    padding: theme.spacing(5),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
  deleteButtonContainer: {
    height: 50,
    width: 50,
  },
  exitButton: {
    float: "right",
  },
  buttonText: {
    marginLeft: "10px",
  },
}));

function AdminItemSizeModal() {
  const classes = useStyles();
  const [showSizeModal, sizes] = useSelector((state) => {
    return [state.config.adminModal.sizeRestriction, state.textInput.sizeRestrictionForm];
  });

  const dispatch = useDispatch();

  const handleSave = () => {
    if (!sizes.length) return;
    const missing = sizes.filter((size, index) => {
      return (
        Object.keys(size).filter((key) => {
          if (size[key].required && size[key].value === "") {
            dispatch(toggleSizeError({ index, name: key }));
            return true;
          }
          return false;
        }).length > 0
      );
    });

    if (missing.length) return;

    // Update item if item is selected, if no selected item exists, treat as new entry to db
    dispatch(hideSizeRestriction());
  };

  const handleExit = () => {
    dispatch(hideSizeRestriction());
    dispatch(toggleAdminModal("item"));
  };
  const handleChange = function (e, index, name) {
    dispatch(updateSizeSettings({ value: e.target.value, name, index }));
  };

  return (
    <Dialog
      open={showSizeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Choice Options
        <IconButton className={classes.exitButton} onClick={handleExit}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {sizes.map((size, index) => (
            <Paper className={classes.paper}>
              <div className={classes.container}>
                <TextField
                  className={classes.textField}
                  label="Choice Name"
                  error={size.sizeName.error}
                  value={size.sizeName.value}
                  onChange={(e) => handleChange(e, index, "sizeName")}
                ></TextField>

                <Input
                  label="Price"
                  type="number"
                  className={classes.priceInput}
                  error={size.price.error}
                  onChange={(e) => handleChange(e, index, "price")}
                  value={size.price.value}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
                <div className={classes.deleteButtonContainer}>
                  <IconButton onClick={() => dispatch(removeSize(index))}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            </Paper>
          ))}
          <div className={classes.container}>
            <Button color="primary" onClick={() => dispatch(addNewSize())}>
              + Add Choice
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="primary" autoFocus onClick={handleSave}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminItemSizeModal;
