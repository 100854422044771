import React, { useState, useEffect } from "react";
import TimePicker from "react-time-picker";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import * as API from "../../api";
import {
  handleInputChange,
  handleDayTimeChange,
  updateCurrentMenu,
  addPickupTime,
  updatePickupTime,
  deletePickupTime,
  setAdminIsFetching,
  setDaysActive,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { toggleAdminModal, toggleDayCheckbox, getMenuItems } from "../../actions";
import UploadButton from "../UploadButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Paper,
  IconButton,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  daysActiveContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 200,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
    padding: theme.spacing(5),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonText: {
    marginLeft: "10px",
  },
  fieldSet: {
    marginTop: "15px",
  },
  dayField: {
    display: "flex",
    margin: "4px",
    padding: "8px 8px 8px 16px",
  },
  timePicker: {
    margin: "6px 8px",
  },
  checkbox: {
    minWidth: "124px",
    textTransform: "capitalize",
  },
  formLabel: {
    marginLeft: "8px",
    marginBottom: "4px",
  },
  pickupTimeWrapper: {
    marginTop: "16px",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
  pickupTimeHeader: {
    marginLeft: "8px",
    marginBottom: "4px",
  },
  pickupTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "85%",
  },
  fullWidthText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  pickupTimePaper: {
    marginTop: "8px",
  },
  timeSeperator: {
    height: 42,
    lineHeight: "42px",
    margin: "0 8px",
    fontWeight: 100,
  },
}));

function AdminMenuModal() {
  const group = "updateMenuForm";
  const classes = useStyles();
  const [showItemModal, formValues] = useSelector((state) => {
    return [state.config.adminModal.menuUpdate, state.textInput.updateMenuForm];
  });
  const times = formValues.daysActive;

  const { id } = useParams();
  const dispatch = useDispatch();

  const toggleMenuModal = function () {
    dispatch(toggleAdminModal("menuUpdate"));
  };

  const handleChange = function (e, name) {
    dispatch(handleInputChange({ value: e.target.value, name, group }));
  };

  const handleCheckbox = function (e) {
    dispatch(
      setDaysActive({
        ...times,
        [e.target.name]: { ...times[e.target.name], active: !times[e.target.name].active },
      })
    );
  };

  const handleDateChange = function (e, name) {
    dispatch(handleInputChange({ value: e, name, group }));
  };

  const handleTimeChange = function (e, day, timeType) {
    dispatch(handleDayTimeChange({ e, day, timeType, group }));
  };

  const handlePickupTimeChange = function (e, index) {
    dispatch(updatePickupTime({ value: e.target.value, index, group }));
  };

  const handleAddPickupTime = function () {
    dispatch(addPickupTime({ group }));
  };

  const handlePickupTimeDelete = function (index) {
    dispatch(deletePickupTime({ index, group }));
  };

  const handleImageUpload = async function (e) {
    dispatch(setAdminIsFetching(true));
    const { data } = await API.uploadImage(e.target.files[0]);
    dispatch(setAdminIsFetching(false));
    dispatch(
      handleInputChange({
        value: data.url,
        name: "imageUpload",
        group,
      })
    );
  };

  const checkDate = (obj) => {
    return (
      Object.prototype.toString.call(new Date(obj)) === "[object Date]" &&
      !isNaN(new Date(obj).getTime())
    );
  };

  const onTimeChange = (day, type, newTime) => {
    dispatch(setDaysActive({ ...times, [day]: { ...times[day], [type]: newTime } }));
  };

  const saveRequirementsMet =
    Object.values(formValues).every((field) => !field.required || field.value) &&
    checkDate(formValues.startDate.value) &&
    checkDate(formValues.endDate.value) &&
    formValues.pickupTimes.every((puTime) => puTime.value);

  const handleSave = function () {
    const { name, startDate, endDate, pickupTimes, imageUpload, description } = formValues;

    const pickupTimesArr = pickupTimes.map((pickupTime) => pickupTime.value);
    dispatch(
      updateCurrentMenu({
        menuId: id,
        menuDetails: {
          name: name.value,
          startDate: new Date(startDate.value),
          endDate: new Date(endDate.value),
          daysActive: times,
          pickupTimes: pickupTimesArr,
          image: imageUpload.value,
          description: description.value,
        },
      })
    );
    dispatch(toggleAdminModal("menuUpdate"));
    dispatch(getMenuItems(id));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog
        open={showItemModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Menu</DialogTitle>
        <DialogContent>
          <div id="alert-dialog-description">
            <div className={classes.container}>
              <TextField
                label="Menu Name"
                error={!formValues.name.value}
                helperText={!formValues.name.value ? "Name cannot be blank" : null}
                className={classes.textField}
                onChange={(e) => handleChange(e, "name")}
                value={formValues.name.value}
              />
              <UploadButton src={formValues.imageUpload.value} onChange={handleImageUpload} />
            </div>
            <div className={classes.container}>
              <TextField
                label="Description (Optional)"
                fullWidth
                className={classes.fullWidthText}
                onChange={(e) => handleChange(e, "description")}
                value={formValues.description.value}
              />
            </div>
            <div className={classes.container}>
              <KeyboardDatePicker
                className={classes.textField}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label="Menu Start Date"
                value={formValues.startDate.value ? new Date(formValues.startDate.value) : ""}
                onChange={(e) => handleDateChange(e, "startDate")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                className={classes.textField}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label="Menu End Date"
                value={formValues.endDate.value ? new Date(formValues.endDate.value) : ""}
                onChange={(e) => handleDateChange(e, "endDate")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <div className={classes.daysActiveContainer}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel className={classes.formLabel} component="legend">
                  Weekdays Active
                </FormLabel>
                <FormGroup>
                  {[
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                    "saturday",
                    "sunday",
                  ].map((day) => (
                    <Paper className={classes.dayField}>
                      <FormControlLabel
                        className={classes.checkbox}
                        control={<Checkbox name={day} />}
                        label={day}
                        checked={times[day].active}
                        onChange={(e) => handleCheckbox(e)}
                      />
                      <TimePicker
                        value={times[day].start}
                        onChange={(val) => onTimeChange(day, "start", val)}
                      />
                      <span className={classes.timeSeperator}>To</span>
                      <TimePicker
                        value={times[day].end}
                        onChange={(val) => onTimeChange(day, "end", val)}
                      />
                    </Paper>
                  ))}
                </FormGroup>
                <div className={classes.pickupTimeWrapper}>
                  <FormLabel className={classes.pickupTimeHeader} component="legend">
                    Pick Up Times
                  </FormLabel>
                  <Button variant="contained" onClick={() => handleAddPickupTime()} color="primary">
                    + Add Pick Up Time
                  </Button>
                </div>
                {formValues.pickupTimes.map((pickupTime, index) => (
                  <Paper className={classes.pickupTimePaper}>
                    <div className={classes.container}>
                      <TextField
                        className={classes.pickupTextField}
                        label="Pick Up Time"
                        value={pickupTime.value}
                        onChange={(e) => handlePickupTimeChange(e, index)}
                      ></TextField>
                      <div>
                        <IconButton onClick={() => handlePickupTimeDelete(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Paper>
                ))}
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleMenuModal} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            disabled={!saveRequirementsMet}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}

export default AdminMenuModal;
