import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleLoader, submitOrder, toggleInputError } from "../actions";
import LoadingBtn from "./Utils/LoadingBtn";
import { CardElement, StripeProvider, Elements, injectStripe } from "react-stripe-elements";

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        ...(padding ? { padding } : {}),
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

class _Pay extends React.Component {
  handleSubmit = async (ev) => {
    ev.preventDefault();
    const { checkout: checkoutInputs } = this.props.textInput;
    const missing = Object.keys(checkoutInputs).filter(
      (c) => checkoutInputs[c].required && !checkoutInputs[c].value
    );
    if (missing.length) {
      missing.forEach((name) => this.props.toggleInputError({ group: "checkout", name }));
      return;
    }

    const emailRegex = new RegExp(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );

    if (!checkoutInputs.emailAddress.value.match(emailRegex)) {
      return this.props.toggleInputError({ group: "checkout", name: "emailAddress" });
    }
    this.props.toggleLoader();
    const token = await this.props.stripe.createToken();
    const { checkout } = this.props;
    if (token.error) return this.props.toggleLoader();
    try {
      await this.props.submitOrder({
        cart: checkout.cart.map((item) => {
          return {
            id: item._id,
            qty: item.quanity,
            selectedSize: item.selectedSize,
          };
        }),
        customer: {
          emailAddress: checkoutInputs.emailAddress.value,
          fullName: checkoutInputs.fullName.value,
          kitchenNotes: checkoutInputs.kitchenNotes.value,
          pickupTime: checkoutInputs.pickupTime.value,
        },
        menu: this.props.menu.selectedMenuId,
        token: token.token,
      });
    } catch (err) {}
    this.props.toggleLoader(false);
  };

  render() {
    return (
      <React.Fragment>
        <div className="clearFix" />
        <form className="checkout__payment__form">
          <div className="checkout__payment__form--container">
            <CardElement {...createOptions(this.props.fontSize)} />
          </div>

          <LoadingBtn
            isFetching={this.props.orders.isFetching}
            clickHandler={this.handleSubmit}
            fetchingMsg={"Submitting. . ."}
            stableMsg={"Submit Order"}
          />
        </form>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ submitOrder, toggleLoader, toggleInputError }, dispatch);
};
const CardForm = connect(mapStateToProps, mapDispatchToProps)(injectStripe(_Pay));

class Stripe extends React.Component {
  constructor() {
    super();
    this.state = {
      elementFontSize: window.innerWidth < 450 ? "14px" : "18px",
    };
  }
  render() {
    return (
      <StripeProvider apiKey={this.props.stripePublicKey}>
        <Elements>
          <CardForm />
        </Elements>
      </StripeProvider>
    );
  }
}

const mapStateToStripeProps = (state) => {
  return state.config;
};

export default connect(mapStateToStripeProps, null)(Stripe);
