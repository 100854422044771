import React from "react";

export default function EmptyCart() {
  const styles = {
    container: {
      margin: "50px",
      textAlign: "center",
      color: "black",
    },
    emptyMsg: {
      fontSize: "20px",
    },
    subMsg: {
      fontSize: "16px",
      color: "#777474",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.emptyMsg}>Your cart is empty!</div>
      <div style={styles.subMsg}>Check back when you're ready to checkout</div>
    </div>
  );
}
