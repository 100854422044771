import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
} from '@material-ui/core';
import AdminOutstandingOrders from './AdminOutstandingOrders';
import { AdminNav, AdminOrderFilters } from '../Components';
import { getOrders, updateStatus, refundOrder, findAllMenus } from '../api';

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [menus, setMenus] = useState([]);
  const [showAll, setShowAll] = useState(true);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [activeOrder, setActiveOrder] = useState({ cart: [] });
  const [showOrderDialog, setShowOrderDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ show: false, title: '', text: '' });
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [menuFilter, setMenuFilter] = useState({ pickupTimes: [] });
  const [pickupTimeFilter, setPickupTimeFilter] = useState('');

  useEffect(() => {
    const callOrders = async () => {
      const [{ data: apiOrders }, { data: apiMenus }] = await Promise.all([
        getOrders(),
        findAllMenus(),
      ]);
      setMenus(apiMenus);
      setOrders(
        apiOrders.map((order) => {
          const {
            confirmation,
            customer,
            orderStatus,
            pickupTime,
            time,
            total,
            cart,
            payment,
            shipping,
            _id,
            menu: menuId,
          } = order;
          return {
            confirmation,
            orderId: _id,
            chargeId: payment.outcome.id,
            customer: customer.fullName,
            email: customer.email,
            kitchenNotes: customer.kitchenNotes,
            orderStatus,
            pickupTime,
            time,
            cart,
            total: total.total,
            shipping,
            menuId,
          };
        })
      );

      setLastUpdateTime(`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`);
    };
    callOrders();

    // Refreshed every 3 minutes
    setInterval(callOrders, 180000);
  }, [setOrders]);

  const filteredOrders = orders
    .filter((order) => showAll || order.orderStatus === 'Processing')
    .filter(
      (order) => !menuFilter._id || menuFilter._id === 'ALL' || order.menuId === menuFilter._id
    )
    .filter(
      (order) =>
        !pickupTimeFilter ||
        pickupTimeFilter === '(All Pickup Times)' ||
        order.pickupTime === pickupTimeFilter
    );

  const styles = {
    keyValuePairs: {
      marginTop: 12,
    },
    key: {
      fontWeight: 600,
      marginRight: 16,
    },
    value: {
      fontWeight: 100,
    },
    btn: { color: '#de3030' },
    lastUpdate: {
      fontStyle: 'italic',
      fontWeight: 100,
      fontSize: 14,
    },
    shippingTitle: {
      fontWeight: 100,
      fontSize: 24,
      margin: '16px 0',
    },
    shippingTable: {
      marginBottom: 12,
    },
  };

  const viewDetails = (order) => {
    setActiveOrder(order);
    setShowOrderDialog(true);
  };

  const handleMenuFilterChange = (menu) => {
    setMenuFilter(menu);
    setPickupTimeFilter('(All Pickup Times)');
  };
  const onConfirmRefund = async () => {
    await refundOrder(activeOrder.chargeId, activeOrder.orderId);
    const itemIndex = orders.findIndex((order) => order.confirmation === activeOrder.confirmation);
    const ordersCopy = [...orders];
    ordersCopy[itemIndex].orderStatus = 'Refunded';
    setOrders(ordersCopy);
    setConfirmDialog({ show: false });
    setShowOrderDialog(false);
  };
  const confirmRefund = () => {
    setConfirmDialog({
      title: 'Confirm Refund',
      text: `Refund ${activeOrder.confirmation}? This can not be undone.`,
      confirmBtnText: 'Yes, Refund Order',
      action: onConfirmRefund,
      show: true,
    });
  };

  const onConfirmStatusChange = async (status) => {
    await updateStatus(activeOrder.confirmation, status);
    const itemIndex = orders.findIndex((order) => order.confirmation === activeOrder.confirmation);
    const ordersCopy = [...orders];
    ordersCopy[itemIndex].orderStatus = status;
    setOrders(ordersCopy);
    setConfirmDialog({ show: false });
    setShowOrderDialog(false);
  };

  const confirmStatusChange = (status) => {
    setConfirmDialog({
      title: 'Confirm Status Change',
      text: `Mark Order ${activeOrder.confirmation} as ${status}? This can not be undone.`,
      confirmBtnText: `Yes, Mark Order as ${status}`,
      action: () => onConfirmStatusChange(status),
      show: true,
    });
  };

  const showOutstandingInv = () => {
    setActiveSectionIndex(1);
    setShowAll(false);
  };

  return (
    <>
      <AdminNav />

      {/* Confirm Dialog */}
      <Dialog open={confirmDialog.show}>
        <DialogTitle>{confirmDialog.title}</DialogTitle>
        <DialogContent>{confirmDialog.text}</DialogContent>
        <Button color='primary' onClick={confirmDialog.action}>
          {confirmDialog.confirmBtnText}
        </Button>
        <Button onClick={() => setConfirmDialog({ show: false })}>No, Cancel</Button>
      </Dialog>

      {/* Order Details Modal */}
      <Dialog
        open={showOrderDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Order Details</DialogTitle>
        <DialogContent>
          <Box m={4}>
            <div style={styles.keyValuePairs}>
              <span style={styles.key}>Customer Name</span>
              <span style={styles.value}>{activeOrder.customer}</span>
            </div>
            <div style={styles.keyValuePairs}>
              <span style={styles.key}>Email</span>
              <span style={styles.value}>{activeOrder.email}</span>
            </div>
            <div style={styles.keyValuePairs}>
              <span style={styles.key}>Kitchen Notes</span>
              <span style={styles.value}>{activeOrder.kitchenNotes}</span>
            </div>
          </Box>
          {activeOrder.shipping && (
            <Paper>
              <Box p={2} m={2}>
                <div style={styles.shippingTitle}>Shipping Info</div>
                <table style={styles.shippingTable}>
                  <tbody>
                    <tr>
                      <td style={styles.key}>Address Line 1</td>
                      <td style={styles.value}>{activeOrder.shipping.address.addressLine1}</td>
                    </tr>
                    <tr>
                      <td style={styles.key}>Address Line 2</td>
                      <td style={styles.value}>{activeOrder.shipping.address.addressLine2}</td>
                    </tr>
                    <tr>
                      <td style={styles.key}>City</td>
                      <td style={styles.value}>{activeOrder.shipping.address.city}</td>
                    </tr>
                    <tr>
                      <td style={styles.key}>State</td>
                      <td style={styles.value}>{activeOrder.shipping.address.state}</td>
                    </tr>
                    <tr>
                      <td style={styles.key}>Zip</td>
                      <td style={styles.value}>{activeOrder.shipping.address.zip}</td>
                    </tr>
                  </tbody>
                </table>

                <div>
                  <hr />
                  <div>
                    <span style={styles.key}>Shipping Method</span>
                    <span style={styles.value}>{activeOrder.shipping.price.courier}</span>
                  </div>

                  <div>
                    <span style={styles.key}>Shipping Paid</span>
                    <span style={styles.value}>
                      ${(activeOrder.shipping.price.total / 100).toFixed(2)}
                    </span>
                  </div>
                </div>
              </Box>
            </Paper>
          )}
          <TableContainer component={Paper}>
            <Table aria-label='Item Table'>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Item</TableCell>
                  <TableCell align='left'>Qty</TableCell>
                  <TableCell align='left'>Option</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeOrder.cart.map((item) => (
                  <TableRow>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>{item.selectedSize || 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Button
          color='primary'
          onClick={() => confirmStatusChange(activeOrder.shipping ? 'Shipped' : 'Complete')}
          disabled={['Complete', 'Shipped', 'Refunded'].includes(activeOrder.orderStatus)}
        >
          Mark As {activeOrder.shipping ? 'Shipped' : 'Complete'}
        </Button>
        <Button
          style={styles.btn}
          onClick={confirmRefund}
          disabled={activeOrder.orderStatus === 'Refunded' || !activeOrder.chargeId}
        >
          Refund Order
        </Button>
        <Button onClick={() => setShowOrderDialog(false)}>Close</Button>
      </Dialog>

      <Box m={6}>
        <Paper>
          <Box p={3}>
            <AdminOrderFilters
              orders={orders}
              menus={menus}
              menuFilter={menuFilter}
              pickupTimeFilter={pickupTimeFilter}
              onMenuChange={handleMenuFilterChange}
              onPickupTimeChange={setPickupTimeFilter}
            />
            <FormControlLabel
              control={<Switch color='primary' />}
              label='View Pending Orders Only'
              onChange={() => setShowAll(!showAll)}
              checked={!showAll}
            />
            <div style={styles.lastUpdate}>Last Updated: {lastUpdateTime}</div>
          </Box>
        </Paper>
      </Box>
      <Box m={6}>
        <Paper>
          <Tabs value={activeSectionIndex} aria-label='Orders Tab'>
            <Tab label='Order Details' onClick={() => setActiveSectionIndex(0)} />
            <Tab label='Inventory Count' onClick={showOutstandingInv} />
          </Tabs>
          <div hidden={activeSectionIndex !== 0}>
            <TableContainer component={Paper}>
              <Table aria-label='order table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Order Number</TableCell>
                    <TableCell align='center'>Customer Name</TableCell>
                    <TableCell align='center'>Order Time</TableCell>
                    <TableCell align='center'>Pickup Time</TableCell>
                    <TableCell align='center'>Order Total</TableCell>
                    <TableCell align='center'>Status</TableCell>
                    <TableCell align='left'>View Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOrders.map((order) => (
                    <TableRow>
                      <TableCell>{order.confirmation}</TableCell>
                      <TableCell>{order.customer}</TableCell>
                      <TableCell>{new Date(order.time).toLocaleString()}</TableCell>
                      <TableCell>{order.pickupTime}</TableCell>
                      <TableCell>${(order.total / 100).toFixed(2)}</TableCell>
                      <TableCell>{order.orderStatus}</TableCell>
                      <TableCell>
                        <Button color='primary' onClick={() => viewDetails(order)}>
                          View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div hidden={activeSectionIndex !== 1}>
            <AdminOutstandingOrders orders={filteredOrders} />
          </div>
        </Paper>
      </Box>
    </>
  );
}
