import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { mainTheme } from "./themes/index";
import { ThemeProvider } from "@material-ui/core";
import { Main, AdminMenuSelection, MenuDetails, OrderConf, AdminOrders } from "./views";
import PrivateRoute from "./PrivateRoute";
import "./App.css";

class App extends Component {
  render() {
    return (
      <Router basename="/">
        <ThemeProvider theme={mainTheme}>
          <Route exact path="/" component={Main} />
          <Route exact path="/success" component={OrderConf} />
          <Route exact path="/shipping" render={() => <Main shipping={true} />} />
          <PrivateRoute path="/admin" exact component={AdminMenuSelection} />
          <PrivateRoute path="/admin/menu/:id" exact component={MenuDetails} />
          <PrivateRoute path="/admin/orders" exact component={AdminOrders} />
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;
