import React, { useEffect } from "react";
import { AdminNav, AdminMenuBox, AdminMenuItemTable } from "../Components";
import { Grid, Box, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getMenuItems,
  toggleAdminModal,
  handleInputChange,
  updateSelectedMenu,
  setSizeSetting,
} from "../actions";
import { AdminItemModal, AdminDeleteItem, AdminMenuUpdate, AdminSizes } from "../Components/Modals";

function MenuDetails() {
  const styles = {
    button: { width: "100%" },
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  const toggleItemModal = function () {
    const textUpdates = {
      category: "",
      name: "",
      description: "",
      price: "",
      sizeCheckbox: false,
      imageUpload: "",
    };
    for (let key in textUpdates) {
      dispatch(
        handleInputChange({
          group: "editItemForm",
          name: key,
          value: textUpdates[key].value,
        })
      );
    }
    dispatch(setSizeSetting());
    dispatch(toggleAdminModal("item"));
  };

  // Only run on mount
  useEffect(() => {
    dispatch(updateSelectedMenu(id));
    dispatch(getMenuItems(id));
  }, []);

  return (
    <div>
      <AdminItemModal />
      <AdminDeleteItem />
      <AdminMenuUpdate />
      <AdminSizes />
      <AdminNav />
      <Box m={6}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AdminMenuBox />
          </Grid>

          <Grid item xs={0} sm={0} md={5} lg={7} />
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleItemModal}
              style={styles.button}
            >
              + Add New Item
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box m={6}>
        <AdminMenuItemTable />
      </Box>
    </div>
  );
}

export default MenuDetails;
