import axios from "axios";

const apiHandler = (method, url, data = {}, headers = {}, params = {}) =>
  axios({
    url: `/api/${url}`,
    method,
    data,
    headers,
    params,
  });

const api = {
  get: (route) => apiHandler("GET", route),
  post: (route, data) => apiHandler("POST", route, data),
  put: (route, data) => apiHandler("PUT", route, data),
  delete: (route, data) => apiHandler("DELETE", route, data),
};

const getAuthHeader = () => ({
  Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
});

const protectedApi = {
  get: (route) => apiHandler("GET", route, {}, getAuthHeader()),
  post: (route, data) => apiHandler("POST", route, data, getAuthHeader()),
  put: (route, data) => apiHandler("PUT", route, data, getAuthHeader()),
  delete: (route, data) => apiHandler("DELETE", route, data, getAuthHeader()),
  uploadFile: (route, data) =>
    apiHandler("POST", route, data, {
      ...getAuthHeader(),
      "content-type": "multipart/form-data",
    }),
};

// Should deprecate - replaced by getMenuInventory
export async function getInventory() {
  return await api.get("inventory");
}

export async function getMenuInventory(menuId) {
  return await api.get(`menu/${menuId}`);
}

export async function getMenus() {
  return await api.get("menu");
}

export async function orderStatus(conf) {
  return await api.get(`orders/status/${conf}`);
}

export async function validateAddress(adrDetails) {
  return await api.post("address/shipping", adrDetails);
}

export async function getConfig() {
  return await api.get("config");
}

export async function placeOrder(orderDetails) {
  return await api.post("orders/new", orderDetails);
}

export function handleLogin(credentials) {
  return api.post("login", credentials);
}
// ==========
// === ADMIN API ROUTES ===
// ==========
export async function newItem({ menuId, itemDetails }) {
  return await protectedApi.post(`admin/menu/${menuId}/inventory`, itemDetails);
}

export async function findAllMenus() {
  return await protectedApi.get("admin/menu");
}
export async function uploadImage(file) {
  const formData = new FormData();
  formData.append("image", file);
  return await protectedApi.uploadFile("admin/image", formData);
}

export async function validateToken() {
  return await protectedApi.get(`admin`);
}

export async function deleteItem({ menuId, itemId }) {
  return await protectedApi.delete(`admin/menu/${menuId}/inventory/${itemId}`, {});
}

export async function updateItem({ menuId, itemId, itemDetails }) {
  return await protectedApi.put(`admin/menu/${menuId}/inventory/${itemId}`, itemDetails);
}

export async function newMenu(newMenu) {
  return await protectedApi.post("admin/menu", newMenu);
}

export async function updateMenu({ menuId, menuDetails }) {
  return await protectedApi.put(`admin/menu/${menuId}`, menuDetails);
}

export async function deleteMenu(menuId) {
  return await protectedApi.delete(`admin/menu/${menuId}`);
}

export async function getOrders() {
  return await protectedApi.get("admin/orders");
}
export async function updateStatus(orderConf, status) {
  return await protectedApi.post(`admin/orders/${orderConf}`, { status });
}

export async function refundOrder(chargeId, orderId) {
  return await protectedApi.post("admin/refund", { chargeId, orderId });
}
