import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector, useDispatch } from "react-redux";
import {
  handleInputChange,
  toggleAdminModal,
  updateSelectedItem,
  setSizeSetting,
} from "../actions";

function AdminMenuItemTable() {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.menu.details.items);

  const rows = items.map((item) => {
    const {
      category: cat,
      desc,
      name,
      price: rawPrice,
      _id,
      image: imageUpload,
      sizeRestricted,
      sizes,
      shippable: shippableCheckbox,
    } = item;
    const price = `${parseInt(rawPrice, 10) / 100}`;
    const category = `${cat.charAt(0).toUpperCase()}${cat.slice(1, cat.length).toLowerCase()}`;
    return {
      category,
      desc,
      name,
      price,
      imageUpload,
      _id,
      sizeRestricted,
      sizes,
      shippableCheckbox,
    };
  });

  const handleConfirmDelete = (data) => {
    dispatch(updateSelectedItem(data._id));
    dispatch(toggleAdminModal("itemDelete"));
  };
  const handleEditClick = function (data) {
    const {
      category,
      name,
      desc: description,
      price,
      imageUpload,
      _id: itemId,
      sizeRestricted: sizeCheckbox,
      sizes,
      shippableCheckbox,
    } = data;

    const textUpdates = {
      category,
      name,
      description,
      price,
      sizeCheckbox,
      imageUpload,
      shippableCheckbox,
    };

    for (let key in textUpdates) {
      dispatch(
        handleInputChange({
          group: "editItemForm",
          name: key,
          value: textUpdates[key],
        })
      );
    }
    dispatch(setSizeSetting(sizes));
    dispatch(updateSelectedItem(itemId));
    dispatch(toggleAdminModal("item"));
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Item Name</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Category</TableCell>
            <TableCell align="right">Edit</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">
                  {row.sizeRestricted ? "Priced by size" : `$${row.price}`}
                </TableCell>
                <TableCell align="right">{row.desc}</TableCell>
                <TableCell align="right">{row.category}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleEditClick(row)}>
                    <EditIcon />
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button onClick={() => handleConfirmDelete(row)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AdminMenuItemTable;
