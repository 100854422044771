import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import { handleInputChange, validateUser } from "../actions";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  adminView: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  logIn: {
    minWidth: "300px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    textAlign: "center",
    alignItems: "center",
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
    margin: "8px",
  },
  avatar: {
    marginBottom: "16px",
    backgroundColor: theme.palette.primary.main,
  },
  logInHeader: {
    marginBottom: "16px",
  },
  logInInput: {
    marginBottom: "16px",
    width: "95%",
  },
}));

export default function AdminPortalLogin(props) {
  const dispatch = useDispatch();
  const sessionToken = window.sessionStorage.getItem("token");
  const { token } = useSelector((state) => state.admin);
  const { username, password } = useSelector((state) => state.textInput.login);
  const classes = useStyles();

  const handleChange = (e, name) => {
    const { value } = e.target;
    dispatch(
      handleInputChange({
        name,
        value,
        group: "login",
      })
    );
  };

  return (
    <div className={classes.adminView}>
      <Card className={classes.logIn}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const credentials = {
              username: username.value,
              password: password.value,
            };
            dispatch(validateUser(credentials));
          }}
        >
          <CardContent className={classes.cardContent}>
            <Avatar className={classes.avatar}>
              <PersonIcon />
            </Avatar>
            <Typography className={classes.logInHeader} variant="h5">
              Admin Portal Login
            </Typography>
            <TextField
              className={classes.logInInput}
              label="Username"
              variant="filled"
              name="username"
              value={username.value}
              onChange={(e) => handleChange(e, "username")}
            />
            <TextField
              className={classes.logInInput}
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="filled"
              name="password"
              value={password.value}
              onChange={(e) => handleChange(e, "password")}
            />
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button type="submit" variant="outlined" color="primary">
              Log In
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
}
