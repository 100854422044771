import React from "react";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toggleAdminModal, deleteCurrentMenu } from "../../actions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

function AdminDeleteMenu() {
  const [showMenuDeleteModal, menu] = useSelector((state) => {
    return [state.config.adminModal.menuDelete, state.admin.menuToDelete];
  });

  const dispatch = useDispatch();

  const toggleItemModal = function () {
    dispatch(toggleAdminModal("menuDelete"));
  };

  const handleDelete = async () => {
    await dispatch(deleteCurrentMenu(menu.id));
    toggleItemModal();
  };

  return (
    <Dialog
      open={showMenuDeleteModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Delete The Following Menu:
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#DC3545",
          }}
          id="alert-dialog-description"
        >
          {menu ? menu.name : ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleItemModal} color="primary">
          No, Cancel
        </Button>
        <Button color="primary" autoFocus onClick={handleDelete}>
          Yes, Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminDeleteMenu;
